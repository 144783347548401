<template>
  <div class="add-form">
    <el-form :model="pvForm" ref="form" size="small" label-position="top" :rules="pvRules">
      <el-form-item :label="$t('location')" required>
        <checkout-list :data.sync="locationList" />
      </el-form-item>

      <el-form-item :label="$t('Namespace')" prop="metadata.namespace">
        <type-list type="namespace" :data.sync="pvForm.metadata.namespace" :locationList="locationList" />
      </el-form-item>

      <el-form-item :label="$t('name')" prop="metadata.name">
        <el-input v-model.trim="pvForm.metadata.name"></el-input>
        <span class="help">{{ $t("tips.nameCommonPattern") }}</span>
      </el-form-item>

      <el-form-item :label="$t('storageClassName')" prop="spec.storageClassName">
        <el-select
          v-model="pvForm.spec.storageClassName"
          :loading="storageClassLoading"
          @focus="initStorageClass"
          filterable
          style="width: 100%;"
        >
          <el-option
            v-for="item in storageClassOptions"
            :key="item.metadata.name"
            :label="item.metadata.name"
            :value="item.metadata.name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('tag')">
        <add-labels :labels.sync="pvForm.metadata.labels" />
        <span class="help">{{ $t("tips.addlabels") }}</span>
      </el-form-item>

      <el-form-item :label="$t('accessModes')">
        <el-radio-group v-model="pvForm.spec.accessModes[0]">
          <el-radio-button label="ReadWriteOnce"></el-radio-button>
          <el-radio-button label="ReadOnlyMany"></el-radio-button>
          <el-radio-button label="ReadWriteMany"></el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('storage')">
        <el-input v-model="pvForm.spec.resources.requests.storage" style="width: 200px;">
          <template slot="append">Gi</template>
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('volumeMode')">
        <el-radio-group v-model="pvForm.spec.volumeMode">
          <el-radio-button label="Filesystem"></el-radio-button>
          <!-- <el-radio-button label="Block"></el-radio-button> -->
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submit" size="small" :loading="loading" plain>
          {{ $t("handle.submit") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { includes, cloneDeep } from "lodash";
import CheckoutList from "@/components/CheckoutList";
import { pvcSubmit } from "api/pv";
import { storageClassListByLocation } from "api/storageClass";
export default {
  components: {
    CheckoutList
  },

  data() {
    return {
      locationList: [],
      loading: false,
      pvForm: {
        metadata: {
          name: "",
          namespace: "",
          labels: {}
        },

        spec: {
          accessModes: ["ReadWriteOnce"],
          resources: {
            requests: {
              storage: "2"
            }
          },
          storageClassName: "",
          volumeMode: "Filesystem"
        }
      },

      pvRules: {
        "metadata.name": [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],
        "metadata.namespace": [{ required: true, message: this.$t("rules.require"), trigger: "change" }],
        "spec.storageClassName": { required: true, message: this.$t("rules.require"), trigger: "change" }
      },

      storageClassLoading: false,
      storageClassOptions: []
    };
  },

  computed: {
    vendor() {
      return this.locationList[0];
    },
    region() {
      return this.locationList[1];
    },
    cluster() {
      return this.locationList[2];
    }
  },

  methods: {
    initStorageClass() {
      if (this.locationList.length > 0) {
        this.storageClassLoading = true;
        storageClassListByLocation({ vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
          response => {
            if (response.code === 0) {
              this.storageClassLoading = false;
              this.storageClassOptions = response.data.items;
            }
          }
        );
      }
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;

          let data = cloneDeep(this.pvForm);
          if (!includes(data.spec.resources.requests.storage, "Gi")) data.spec.resources.requests.storage += "Gi";

          pvcSubmit(data, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.$router.push({ path: `/list/PersistentVolumeClaims` });
            }
          });
        }
      });
    }
  }
};
</script>

<style></style>
